import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'

const NavBarBottomSubLink = ({ image, label, href }) =>
  <Link
    className='navbar-bottom-sub-link'
    to={href}
  >
    {image &&
    <img
      src={image?.url}
      alt={image?.alternativeText}
    />}
    {label}
  </Link>

export default NavBarBottomSubLink

NavBarBottomSubLink.propTypes = {
  image: StrapiImagePropType,
  label: PropTypes.string,
  href: PropTypes.string
}

NavBarBottomSubLink.defaultProps = {
  image: {},
  label: '',
  href: ''
}
