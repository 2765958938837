import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const getParagraphClassName = (className, index) => classNames(
  className,
  {
    'first': index === 0
  },
)

const Paragraphs = ({ text, className }) =>
  text.split('\n').map((paragraph, index) =>
    <p
      className={getParagraphClassName(className, index)}
      key={index}
    >
      {paragraph}
    </p>
  )

export default Paragraphs

Paragraphs.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

Paragraphs.defaultProps = {
  text: '',
  className: '',
}
