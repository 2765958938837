import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const getFooterColClassName = (className) => classNames(
  'footer-left__col',
  className
)

const FooterCol = ({ header, className, children }) =>
  <div className={getFooterColClassName(className)}>
    <p className='header'>{header}</p>
    {children}
  </div>

export default FooterCol

FooterCol.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

FooterCol.defaultProps = {
  header: '',
  className: '',
  children: {}
}
