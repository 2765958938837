import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

const NavBarBottomBar = ({ arrowOnClick }) =>
  <div className='navbar-bottom-bar'>
    <div className='navbar-bottom-bar__circle' />
    <div
      className='navbar-bottom-bar__arrow navbar-bottom-bar__arrow--pointing-down'
      onClick={arrowOnClick}
      onKeyDown={arrowOnClick}
      tabIndex={0}
      aria-label='Toggle navigation'
      aria-controls='navbarSupportedContent'
      aria-expanded='false'
      data-toggle='collapse'
      role='button'
    />
    <div
      className='navbar-bottom-bar__arrow navbar-bottom-bar__arrow--pointing-up'
      onClick={arrowOnClick}
      onKeyDown={arrowOnClick}
      tabIndex={0}
      aria-label='Toggle navigation'
      aria-controls='navbarSupportedContent'
      aria-expanded='false'
      data-toggle='collapse'
      role='button'
    />
    <div className='navbar-bottom-bar__menu'>MENU</div>
  </div>

export default NavBarBottomBar

NavBarBottomBar.propTypes = {
  arrowOnClick: PropTypes.func
}

NavBarBottomBar.defaultProps = {
  arrowOnClick: noop
}
