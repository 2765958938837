import React from 'react'
import NavBar from '../NavBar/NavBar'
import '@fontsource/rubik'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/300.css'
import '@fontsource/roboto/400.css'
import '../../styles/styles.scss'
import { graphql, useStaticQuery } from 'gatsby'
import Footer from '../Footer/Footer'
import NavBarBottom from '../NavBarBottom/NavBarBottom'
import { Helmet } from 'react-helmet'

const Layout = ({ description, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      ...navBarFragment
      ...footerFragment
      ...companyInfoFragment
    }
  `)

  const {
    strapiNavBar,
    strapiFooter,
    strapiCompanyInfo
  } = data

  return <div className='layout'>
    <Helmet htmlAttributes={{ lang: 'de', }}>
      <meta
        name='description'
        content={description}
      />
      <meta charSet='utf-8' />
      <title>Senprima</title>
    </Helmet>
    <NavBar {...strapiNavBar} />
    <NavBarBottom {...strapiNavBar} />
    {children}
    <Footer {...strapiFooter} {...strapiCompanyInfo} />
  </div>
}

export default Layout
