import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { NavBarSubLinkPropType } from '../propTypes'

const NavBarLinkWithDropdown = ({ href, label, subLinks }) =>
  <div className='navbar-dropdown'>
    {href
      ? <Link
        to={href}
        className='navbar-link'
      >
        {label}
      </Link>
      : <div className='navbar-link'>
        {label}
      </div>}
    <div className='navbar-sub-links'>
      {subLinks.map(subLink =>
        <Link
          key={subLink.label}
          className='navbar-sub-link'
          to={subLink.href}
        >
          {subLink.label}
        </Link>)}
    </div>
  </div>

export default NavBarLinkWithDropdown

NavBarLinkWithDropdown.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  subLinks: PropTypes.arrayOf(NavBarSubLinkPropType)
}

NavBarLinkWithDropdown.defaultProps = {
  label: '',
  subLinks: []
}
