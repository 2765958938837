import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const getSectionWrapperClassName = (backgroundColor, backgroundColorMd, backgroundColorSm, boxShadow) => classNames(
  'section-wrapper',
  {
    [`section-wrapper-${backgroundColor}`]: !!backgroundColor
  },
  {
    [`section-wrapper-md-${backgroundColorMd}`]: !!backgroundColorMd
  },
  {
    [`section-wrapper-sm-${backgroundColorSm}`]: !!backgroundColorSm
  },
  {
    'section-wrapper-box-shadow': boxShadow
  },
)

const getContentClassName = (className) => classNames(
  className,
  'section-wrapper__content',
)

const SectionWrapper = ({
  className,
  children,
  backgroundColor,
  backgroundColorMd,
  backgroundColorSm,
  boxShadow,
  id
}) =>
  <div
    className={`${getSectionWrapperClassName(backgroundColor, backgroundColorMd, backgroundColorSm, boxShadow)}`}
    id={id}
  >
    <div className={`${getContentClassName(className)}`}>
      {children}
    </div>
  </div>

export default SectionWrapper

SectionWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.oneOf(['teal', 'light-teal-gradient', 'horizontal-dark-teal-gradient', 'ming-gradient', '']),
  backgroundColorMd: PropTypes.oneOf(['white', 'gradient-light-to-dark-teal', 'teal', '']),
  backgroundColorSm: PropTypes.oneOf(['white', '']),
  boxShadow: PropTypes.bool
}

SectionWrapper.defaultProps = {
  className: '',
  backgroundColor: '',
  backgroundColorMd: '',
  backgroundColorSm: '',
  boxShadow: false
}
