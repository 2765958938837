import React, { useState } from 'react'
import classNames from 'classnames'
import NavBarBottomLink from './NavBarBottomLink'
import NavBarBottomBar from './NavBarBottomBar'
import { NavBarLinkPropType } from '../propTypes'
import PropTypes from 'prop-types'

const getNavBarBottomClassName = (isActive) => classNames(
  'navbar-bottom',
  {
    'navbar-bottom-active': isActive
  },
  {
    'navbar-bottom-inactive': isActive === false
  },
)

const NavBarBottom = ({ links }) => {
  const [navBarBottomActive, setNavBarBottomActive] = useState(null)
  const [linkActive, setLinkActive] = useState(null)

  const handleLinkOnClick = (innerHTML) => {
    if (innerHTML === linkActive) {
      setLinkActive(null)
    } else {
      setLinkActive(innerHTML)
    }
  }

  return <div className={getNavBarBottomClassName(navBarBottomActive)}>
    <NavBarBottomBar arrowOnClick={() => setNavBarBottomActive(!navBarBottomActive)}/>
    <div className='navbar-bottom-links'>
      {links.map(link =>
        <NavBarBottomLink
          key={link.label}
          {...link}
          linkActive={linkActive}
          onClick={handleLinkOnClick}
        />
      )}
    </div>
  </div>
}

export default NavBarBottom

NavBarBottom.propTypes = {
  links: PropTypes.arrayOf(NavBarLinkPropType)
}

NavBarBottom.defaultProps = {
  links: []
}
