import React, { useState } from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/logoSenprimaTerracottaTeal.svg'
import { NavBarLinkPropType } from '../propTypes'
import NavBarLinks from './NavBarLinks'
import { Link } from 'gatsby'
import useDocumentScrollThrottled from '../useDocumentScrollThrottled'
import classNames from 'classnames'

const getNavBarClassName = (hideNavBar) => classNames(
  'navbar',
  { 'navbar-hidden': hideNavBar },
  { 'navbar-visible': !hideNavBar && hideNavBar !== null }
)

const APPEAR_BEFORE = 800
const MINIMUM_SCROLL = 64
const TIMEOUT_DELAY = 200

const NavBar = ({ links }) => {
  const [hideNavBar, setHideNavBar] = useState(null)

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setTimeout(() => {
      if (currentScrollTop < APPEAR_BEFORE) {
        setHideNavBar(null)
      } else {
        setHideNavBar(isScrolledDown && isMinimumScrolled)
      }
    }, TIMEOUT_DELAY)
  })
  
  return <div className={getNavBarClassName(hideNavBar)}>
    <Link
      className='navbar__logo'
      to='/'
    >
      <img
        src={logo}
        alt='Senprima logo'
      />
    </Link>
    <div className='navbar__links'>
      <NavBarLinks links={links} />
    </div>
  </div>
}

export default NavBar

NavBar.propTypes = {
  links: PropTypes.arrayOf(NavBarLinkPropType)
}

NavBar.defaultProps = {
  links: []
}
