import Paragraph from '../Paragraph/Paragraph'
import { Link } from 'gatsby'
import React from 'react'
import FooterCol from './FooterCol'
import PropTypes from 'prop-types'
import { ListItemPropType, SocialPropType } from '../propTypes'

export const FooterColText = ({ header, text }) =>
  <FooterCol header={header}>
    <Paragraph
      className='text'
      text={text}
    />
  </FooterCol>

FooterColText.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
}

FooterColText.defaultProps = {
  header: '',
  text: '',
}

export const FooterColPhoneNumber = ({ header, phoneNumber }) =>
  <FooterCol header={header}>
    <a
      href={`tel:${phoneNumber}`}
      className='text footer-phone-number'
    >
      {phoneNumber}
    </a>
  </FooterCol>

FooterColText.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
}

FooterColText.defaultProps = {
  header: '',
  text: '',
}

export const FooterColLinks = ({ header, links }) =>
  <FooterCol header={header}>
    {links.map(link =>
      <Link
        className='link'
        key={link?.href}
        to={link?.href}
      >
        {link?.label}
      </Link>
    )}
    <a className='link' target='_blank' href="https://whistleblowersoftware.com/secure/SenPrima">Whistleblower Portal</a>
  </FooterCol>

FooterColLinks.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(ListItemPropType),
}

FooterColLinks.defaultProps = {
  header: '',
  links: '',
}

export const FooterColSocials = ({ header, socials }) =>
  <FooterCol
    header={header}
    className='socials'
  >
    <div className='socials__icons'>
      {socials?.map(social =>
        <a
          href={social.href}
          key={social.href}
        >
          <img
            src={social?.image?.url}
            alt={social?.image?.alternativeText}
          />
        </a>
      )}
    </div>
  </FooterCol>

FooterColSocials.propTypes = {
  header: PropTypes.string,
  socials: PropTypes.arrayOf(SocialPropType),
}

FooterColSocials.defaultProps = {
  header: '',
  socials: '',
}
