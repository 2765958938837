import PropTypes from 'prop-types'

export const ListItemPropType = PropTypes.shape({
  text: PropTypes.string
})

export const StrapiImagePropType = PropTypes.shape({
  alternativeText: PropTypes.string,
  url: PropTypes.string
})

export const GatsbyImageDataPropType = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
  images: PropTypes.shape({
    sources: PropTypes.array,
    fallback: PropTypes.shape({
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
    layout: PropTypes.string,
    placeholder: PropTypes.shape({
      fallback: PropTypes.string,
    })
  })
})

export const StrapiImageSharpPropType = PropTypes.shape({
  alternativeText: PropTypes.string,
  localFile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: GatsbyImageDataPropType
    })
  })
})

export const StepPropType = PropTypes.shape({
  roundImage: PropTypes.string.isRequired,
  roundImageAlt: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  attachedImage: PropTypes.string,
  attachedImageAlt: PropTypes.string,
  arrowOnLeft: PropTypes.bool,
  arrow: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string
})

export const HeaderWithDescriptionPropType = PropTypes.shape({
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
})

export const ServicePropType = PropTypes.shape({
  name: PropTypes.string,
  price: PropTypes.string
})

export const ServicesPropType = PropTypes.shape({
  additionalServices: PropTypes.arrayOf(ServicePropType),
  basicServices: PropTypes.arrayOf(ServicePropType),
})

export const TabPropType = PropTypes.shape({
  image: StrapiImageSharpPropType,
  description: PropTypes.string,
  additionalServices: PropTypes.arrayOf(ServicePropType),
  basicServices: PropTypes.arrayOf(ServicePropType),
  activeTab: PropTypes.number,
})

export const LinkButtonPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
})

export const TilePropType = PropTypes.shape({
  image: StrapiImagePropType.isRequired,
  header: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
})

export const CompanyInfoPropType = PropTypes.shape({
  body: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
})

export const DataProtectionLinkPropType = PropTypes.shape({
  link: PropTypes.string.isRequired,
  text: PropTypes.string
})

export const PointPropType = PropTypes.shape({
  header: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyInfoArray: PropTypes.arrayOf(CompanyInfoPropType),
  links: PropTypes.arrayOf(DataProtectionLinkPropType)
})

export const OpinionPropType = PropTypes.shape({
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  opinion: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired
})

export const ImprintParagraphPropType = PropTypes.shape({
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
})

export const ImprintContactPropType = PropTypes.shape({
  header: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  faxNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
})

export const ServiceIconsPropType = PropTypes.shape({
  image: StrapiImagePropType.isRequired,
  header: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
})

export const ServiceContentPartPropType = PropTypes.shape({
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  bulletPoints: PropTypes.arrayOf(ListItemPropType),
  orangeHeadline: PropTypes.bool,
  orangeBorder: PropTypes.bool
})

export const JobOfferPartPropType = PropTypes.shape({
  header: PropTypes.string,
  description: PropTypes.string,
  bulletPoints: PropTypes.arrayOf(ListItemPropType)
})

export const IconWithTextPropType = PropTypes.shape({
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImageSharpPropType
})

export const CareerIconPropType = PropTypes.shape({
  image: StrapiImagePropType,
  header: PropTypes.string
})

export const JobOfferPropType = PropTypes.shape({
  image: StrapiImageSharpPropType,
  location: PropTypes.string,
  thumbnailHeader: PropTypes.string,
  thumbnailDescription: PropTypes.string,
  url: PropTypes.string,
})

export const NavBarSubLinkPropType = PropTypes.shape({
  image: StrapiImagePropType,
  label: PropTypes.string,
  href: PropTypes.string
})

export const NavBarLinkPropType = PropTypes.shape({
  label: PropTypes.string,
  href: PropTypes.string,
  subLinks: PropTypes.arrayOf(NavBarSubLinkPropType)
})

export const SocialPropType = PropTypes.shape({
  href: PropTypes.string,
  image: StrapiImagePropType
})

export const ServiceHealthcareBoxPropType = PropTypes.shape({
  header: PropTypes.string,
  description: PropTypes.string,
  button: LinkButtonPropType
})

export const LocationPropType = PropTypes.shape({
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string
})

export const PartnershipTilesPropType = PropTypes.shape({
  image: StrapiImageSharpPropType,
  header: PropTypes.string,
  description: PropTypes.string,
  button: LinkButtonPropType
})

export const EmployeePropType = PropTypes.shape({
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string
})
