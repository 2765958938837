import React from 'react'
import { Link } from 'gatsby'
import NavBarLinkWithDropdown from './NavBarLinkWithDropdown'
import PropTypes from 'prop-types'
import { NavBarLinkPropType } from '../propTypes'

const NavBarLinks = ({ links }) =>
  links.map((link, index) =>
    <React.Fragment key={index}>
      {link?.subLinks.length === 0
        ? <Link
          className='navbar-link'
          to={link.href}
        >
          {link.label}
        </Link>
        : <NavBarLinkWithDropdown {...link}/>
      }
    </React.Fragment>
  )

export default NavBarLinks

NavBarLinks.propTypes = {
  links: PropTypes.arrayOf(NavBarLinkPropType)
}

NavBarLinks.defaultProps = {
  links: []
}
