import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import { ListItemPropType, SocialPropType } from '../propTypes'
import logo from '../../images/logoGradientTerracottaWhite.svg'
import { FooterColLinks, FooterColPhoneNumber, FooterColSocials, FooterColText } from './footerComponents'

const Footer = ({ address, phoneNumber, links, socials }) => <>
  <div className='footer-gradient-bar' />
  <SectionWrapper
    className='footer'
    backgroundColor='teal'
  >
    <div className='footer-left'>
      <FooterColText
        header='Adresse:'
        text={address}
      />
      <FooterColPhoneNumber
        header='Kontaktieren Sie uns:'
        phoneNumber={phoneNumber}
      />
      <FooterColLinks
        header='Links:'
        links={links}
      />
      <FooterColSocials
        header='Folgen Sie uns'
        socials={socials}
      />
    </div>
    <div className='footer-right'>
      <img
        src={logo}
        alt='Senprima logo'
      />
    </div>
  </SectionWrapper>
</>

export default Footer

Footer.propTypes = {
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  links: PropTypes.arrayOf(ListItemPropType),
  socials: PropTypes.arrayOf(SocialPropType)
}

Footer.defaultProps = {
  address: '',
  phoneNumber: '',
  links: [],
  socials: []
}
