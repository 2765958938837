import React from 'react'
import classNames from 'classnames'
import NavBarBottomSubLink from './NavBarBottomSubLink'
import PropTypes from 'prop-types'
import { NavBarSubLinkPropType } from '../propTypes'
import { noop } from 'lodash'
import { Link } from 'gatsby'

const getLinkClassName = (linkActive, label) => classNames(
  'navbar-bottom-link',
  {
    'navbar-bottom-link-active': linkActive === label
  },
)

const getSubLinksClassName = (linkActive, label) => classNames(
  'navbar-bottom-sub-links',
  {
    'navbar-bottom-sub-links-active': linkActive === label
  },
)

const NavBarBottomLink = ({ label, href, subLinks, linkActive, onClick }) =>
  <div className='navbar-bottom-link-wrapper'>
    {subLinks.length === 0
      ? <Link
        className='navbar-bottom-link'
        to={href}
      >
        {label}
      </Link>
      : <>
        <div
          className={getLinkClassName(linkActive, label)}
          onClick={({ target: { innerHTML } }) => onClick(innerHTML)}
          onKeyDown={({ target: { innerHTML } }) => onClick(innerHTML)}
          role='button'
          tabIndex={0}
        >
          {label}
        </div>
        <div className={getSubLinksClassName(linkActive, label)}>
          {subLinks.map(subLink =>
            <NavBarBottomSubLink
              key={subLink.label}
              {...subLink}
            />)}
        </div>
      </>
    }
  </div>

export default NavBarBottomLink

NavBarBottomLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  subLinks: PropTypes.arrayOf(NavBarSubLinkPropType),
  linkActive: PropTypes.string,
  onClick: PropTypes.func
}

NavBarBottomLink.defaultProps = {
  label: '',
  href: '',
  subLinks: [],
  linkActive: '',
  onClick: noop
}

